export const useProfileStore = defineStore('supabase/profile', () => {
  const log = supabaseStores.log
  const { useSupabase } = supabaseStores.useAuthStore()
  const { customer } = shopifyStores.useCustomerStore()

  const loading = ref(false)
  const picture = ref<string | null>()

  const path = computed(() => `${customer?.nid}/profile`)

  const fetchPicture = async () => {
    loading.value = true
    try {
      const { supabase } = await useSupabase()
      const { data } = await supabase
        .storage
        .from('profiles')
        .createSignedUrl(path.value, 86400, {
          transform: {
            quality: 85,
            width: 640,
            height: 640
          }
        })
      // set picture to null instead of undefined so we know we've tried to fetch
      picture.value = data?.signedUrl || null
    } catch (e) {
      picture.value = null
    } finally {
      loading.value = false
    }
  }

  const uploadPicture = async (file: File) => {
    const { supabase } = await useSupabase()
    loading.value = true
    const { error } = await supabase
      .storage
      .from('profiles')
      .upload(path.value, file, {
        upsert: true,
        contentType: 'image/png'
      })
    if (error) {
      log('could not upload picture')
      loading.value = false
      return null
    }
    await fetchPicture()
  }

  const removePicture = async () => {
    picture.value = undefined
    const { supabase } = await useSupabase()
    const { error } = await supabase
      .storage
      .from('profiles')
      .remove([ path.value ])
    if (error) {
      log('could not remove picture')
    }
  }

  const usePicture = () => {
    // only load if picture is undefined, not null to prevent reloading if picture is simply blank
    if (picture.value === undefined && loading.value === false) {
      fetchPicture()
    }
    return picture
  }

  const reset = () => {
    picture.value = undefined
    loading.value = false
  }

  return {
    loading,
    picture,
    fetchPicture,
    usePicture,
    uploadPicture,
    removePicture,
    reset
  }
})